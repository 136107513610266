







































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppListUser from '@/components/AppListUser.vue';
import AppListItem from '@/components/AppListItem.vue';
import USER from '@/graphql/queries/User.graphql';
import { User } from '@/types/schema';

export default Vue.extend({
  name: 'Profile',
  components: {
    AppMain,
    AppListUser,
    AppListItem,
  },
  data() {
    return {
      user: {} as User,
    };
  },
  computed: {
    hasUser(): boolean {
      return this.user && !!this.user.id;
    },
  },
  apollo: {
    user: {
      query: USER,
    },
  },
});
